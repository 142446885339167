import { Suspense, useEffect, useState } from "react";
import { ReactComponent as Close } from "assets/close.svg";
import { LoadingIndicator } from "components";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { Outlet } from "react-router-dom";
import { keycloakService } from "services/keycloakService";

export const MainLayout = () => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  useEffect(() => {
    setIsClosed(!!sessionStorage.getItem("newPortalPopupClosed"));
  }, []);

  const onClose = () => {
    sessionStorage.setItem("newPortalPopupClosed", "true");
    setIsClosed(true);
  };

  return (
    <>
      <div className="box-border text-gray-900 bg-gray-50 h-[calc(100vh-0.5rem)]">
        <ErrorBoundary>
          <Suspense fallback={<LoadingIndicator center />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </div>
      {!isClosed && (
        <div className="z-[999] fixed top-0 left-0 bg-gray-700/50 h-full w-full flex justify-center items-center">
          <div className="relative min-w-[40%] border rounded-xl p-5 bg-white min-h-[150px] flex flex-col gap-4">
            <div
              onClick={() => onClose()}
              className="cursor-pointer absolute top-0 right-0 m-2"
            >
              <Close />
            </div>

            <div className="flex select-none justify-center items-center">
              <img
                src="https://minside.capguard.no/auth/resources/3ffqy/login/fa-clientportal-capguard-theme/img/cap-logo-dark.png"
                className="h-[40px]"
              />
            </div>
            <div className="flex justify-center text-center">
              Invento Kapitalforvaltning AS byttet navn til Capguard Asset
              Management AS den 1. september 2024
            </div>
            <div className="flex justify-center items-center select-none">
              <button
                onClick={() => {
                  window.open("https://minside.capguard.no", "_blank");
                  keycloakService.onAuthLogout();
                }}
                className="bg-[#213021] text-white px-4 py-2 rounded-md"
              >
                Klikk for å gå til ny kundeportal
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
